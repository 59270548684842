<template>
  <div>
    <h2>Bemessung Fuge</h2>
    <p>Die Erforderliche Fugenbewehrung wird unter Berücksichtigung der Mindestfugenbewehrung bestimmt.</p>
  </div>
</template>

<script>
export default {
  name: "ResultTabThreeHelp"
};
</script>

<style lang="scss">
</style>